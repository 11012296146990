section#media {
    margin-top: 0;
}

.video {
    width: 100%;
    height: 10rem;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    position:relative;
}

.video__container {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.head {
    color: var(--color-grey);
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 3rem;
    margin-bottom: none;
    text-transform: capitalize;
}

.video__image {
    width: 100%;
    position:relative;
}

.video__image img {
    width: 100%;
    display: block;
}

.video__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-right: 2rem;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
}

.video__content h2 {
    color: var(--color-white);
    text-align: center;
}

.video__content a {
    font-size: 1.5rem;
    width: 3rem;
    background: var(--color-primary);
    margin-top: .5rem;
    padding: 0.8rem;
    border-radius: 0.7rem;
    transition: var(--transition);
}

.video__content:hover {
    opacity: 1;
}

.video__content > * {
    transform: translateY(25px);
    transition: transform 0.6s;
}

.video__content:hover > * {
    transform: translateY(0px);
    transition: transform 0.6s;
}

.media__container {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
}

.media__container article {
    border: 1rem solid transparent;
    transition: var(--transition);
}

.media__container article:hover {
    border-color: var(--color-primary);
}

@media screen and (max-width: 1024px) {
    
    .media__container, .video__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .media__container article {
        border: 0;
    }
}

@media screen and (max-width: 600px) {
    .media__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .video__container {
        grid-template-columns: 1fr;
        gap:1rem;
    }
}