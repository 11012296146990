.main__header {
    width: 100%;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    background: url(/src/images/teampictureupdated.jpg);
    background-size: 100%;
    background-color: rgb(84, 84, 84);
    background-blend-mode: multiply;
}

.main__header-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
    z-index:5;
    margin-left: 100vh;
}

.main__header-center {
    margin-top: -3rem;
    text-align: center;
    align-items: center;
}

.main__header-center h1 {
    color: var(--color-white);
}

.main__header-center p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;
    color: var(--color-white);
}

.main__header-center .btn.lg {
    margin-right: 3rem;
}

.outer-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.inner-heading {
    border: 0px solid #ddd;
    height: 50px;
    line-height: 50px;
    font-weight: 900;
    font-size: 3vw;
    text-transform: uppercase;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.inner-heading span {
    position: relative;
    color: var(--color-secondary);
    animation: animation 10s ease infinite;
}

@keyframes animation {
    0%,
    100% {
        top:0;
    }

    20% {
        top: 0;
    }
    25% {
        top: -50px;
    }
    45% {
        top: -50px;
    }
    50% {
        top:-100px;
    }
    70% {
        top:-100px;
    }
}

/* Pages Redirect */

.pagesdirect {
    margin-top: 4rem;
    background:var(--color-darkgrey);
    font-family: 'Russo One', sans-serif, monospace;
}

.pages__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin-top: 4rem;
    font-style: italic;
}

.pages__page a {
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pages_page:hover a {
    background: var(--color-secondary);
    color: var(--color-gray-600);
}

/* Footer */

footer {
    background: var(--color-darkgrey);
    margin-top: 5rem;
    padding-top: 5rem;
    font-size: 0.9rem;
    color: var(--color-white);
    border: 5px black;
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article p {
    margin-top: 0.5rem;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__container article #Link {
    transition: var(--transition);
}

.footer__container article #Link:hover {
    color:var(--color-fadedred);
}

.footer__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer__socials a {
    background: var(--color-primary);
    padding: 0.7rem;
    font-size: 3vh;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.footer__socials a svg {
    color: var(--color-grey)
}

.footer__socials a:hover {
    border-color: var(--color-fadedred);
    background: var(--color-fadedred);
}

.footer__copyright {
    color: var(--color-grey);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid var(--color-secondary);
    margin-top: 5rem;
}



@media screen and (max-width: 1024px) {
    .main__header {
        width: 100%;
        height: fit-content;
        padding: 12rem 0;
        margin-left: none;
        overflow: hidden;
        
    } 

    .inner-heading {
        font-size: 5vw;
    }

    .main__header-container {
        gap: 0;
        align-items: center;
    }

    .main__header-image {
        width: 20rem;
        height: 20rem;
    }

    .footer__container {
        grid-template-columns: 1fr;
        gap: 4rem;
    }
}

/* Media Queries for Smaller Screens */

@media screen and (max-width: 600px) {

    .main__header {
        margin-top: 0;
        padding: 12rem 0 0;
        height: 100vh;
        overflow: hidden;
        background-color: rgb(84, 84, 84);
    }

    .main__header-center-image {
        display: none;
    }

    .main__header-container {
        grid-template-columns: 1fr;
        margin-left: 1rem;
    }

    .outer-heading {
        margin-left : 0;
        padding: 0 1rem;
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 1rem;
        width: 100%;
    }

    .outer-heading p {
        font-size: 3vw;
        overflow: hidden;
    }

    .inner-heading {
        font-size: 6vw;
        justify-content: center;
        align-items: center;
    }

    .inner-heading span {
        font-size: 10vw;
    }

    .pages__wrapper {
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 3rem;
    }

    .pages__page {
        width: 84%;
        margin: 0 auto;
    }

    .faqs__wrapper {
        gap: 1rem;
        margin-top: 3rem;
    }

    footer {
        margin-top: 7rem;
    }

    .footer__container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer__container article {
        align-items: center;
    }

    .footer__container article p {
        text-align: center;
    }
}